var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    {
      ref: "updateForm",
      staticClass: "w-screen min-contents",
      class: [_vm.isImpersonated ? "mb-4" : "mb-10"],
      attrs: { tag: "div" },
    },
    [
      _c(
        "div",
        { staticClass: "container mx-auto grid grid-cols-12 gap-x-6 mt-2" },
        [
          _c(
            "h2",
            { staticClass: "col-span-5 text-4xl text-left font-semibold" },
            [
              _vm.defaultConsumer && _vm.defaultConsumer.id
                ? _c("span", [
                    _vm._v(
                      " [" +
                        _vm._s(_vm._f("padZero")(_vm.defaultConsumer.id)) +
                        "] "
                    ),
                  ])
                : _vm._e(),
              _vm.defaultConsumer.company_name
                ? _c("span", [_vm._v(_vm._s(_vm.defaultConsumer.company_name))])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-span-7 flex items-end justify-between" },
            [
              _c(
                "a",
                {
                  staticClass: "block my-auto",
                  attrs: {
                    href: _vm.defaultConsumer.company_url,
                    target: "_new",
                  },
                },
                [
                  _c("button", { staticClass: "py-2 px-5 my-auto web_btn" }, [
                    _c("span", { staticClass: "inline-block" }, [
                      _vm._v("web"),
                    ]),
                    _c("img", {
                      staticClass: "inline-block w-4 h-4 ml-2",
                      attrs: {
                        src: require("@/assets/image/icon_web_link.svg"),
                      },
                    }),
                  ]),
                ]
              ),
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-between my-auto font-bold h-10",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "py-2 px-4 mr-2 action_btn",
                          on: {
                            click: function ($event) {
                              _vm.isEdit = !_vm.isEdit
                            },
                          },
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "py-2 px-4 ml-2 action_btn",
                          on: { click: _vm.updateConsumer },
                        },
                        [_vm._v("save")]
                      ),
                    ]
                  )
                : _c("div", { staticClass: "flex my-auto font-bold h-10" }, [
                    _vm.loginUserRole === "manager"
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "py-2 px-4 mr-2 bg-light-yellow action_btn",
                            on: {
                              click: function ($event) {
                                return _vm.impersonatedLogin(
                                  "consumer",
                                  _vm.consumer.uuid
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "pages.administrators.commons.impersonate"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass:
                          "py-2 px-4 mr-2 bg-base-yellow text-black font-semibold text-sm",
                        on: {
                          click: function ($event) {
                            _vm.isEdit = !_vm.isEdit
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("controls.edit")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "py-2 px-4 ml-2 bg-base-yellow text-black font-semibold text-sm",
                        on: { click: _vm.openPreview },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("controls.preview")) + " ")]
                    ),
                  ]),
            ]
          ),
          _c("div", { staticClass: "col-span-8 text-right" }),
          _c("p", { staticClass: "py-2 col-span-4 text-xs text-right" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("pages.consumers.preview.notice")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "container mx-auto grid grid-cols-12 gap-x-6 pt-5" },
        [
          _c(
            "label",
            { staticClass: "col-start-1 col-span-2 py-2 text-right" },
            [_vm._v(_vm._s(_vm.$t("commons.fields.terms_and_conditions")))]
          ),
          _c("div", { staticClass: "col-span-10 py-2" }, [
            _vm.consumer.agree_to_terms
              ? _c(
                  "button",
                  {
                    staticClass:
                      "h-full w-full py-1 bg-base-yellow text-xl font-semibold text-black text-center",
                    on: { click: _vm.downloadPdf },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("commons.fields.download_agreed")) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass:
                      "h-full w-full py-1 bg-green-400 font-bold text-center",
                    on: {
                      click: function ($event) {
                        return _vm.$modal.show("tacModal")
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.consumers.profile.open_tac_screen")
                        ) +
                        " "
                    ),
                  ]
                ),
          ]),
        ]
      ),
      _c("InputConsumerFields", {
        attrs: {
          fieldType: "edit",
          isEdit: _vm.isEdit,
          errors: _vm.otherFieldErrors,
        },
        on: { setTelContryCode: _vm.setTelContryCode },
        model: {
          value: _vm.consumer,
          callback: function ($$v) {
            _vm.consumer = $$v
          },
          expression: "consumer",
        },
      }),
      _vm.isImpersonated
        ? _c(
            "div",
            {
              staticClass: "admin_contents mt-12 border-t-2 bg-light-grey pb-2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "container mx-auto grid grid-cols-12 gap-6 mt-6",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-start-1 col-span-2 text-right tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("commons.fields.previous_purchase_amount")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-10 text-left ml-3 tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " ￥" +
                          _vm._s(
                            _vm._f("price")(_vm.previous_purchase_amount)
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-start-1 col-span-2 text-right tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("commons.fields.purchase_genre_ratio")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-10 ml-3 grid grid-cols-7 text-center text-sm",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-red-400",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-yellow-500",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-yellow-200",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-green-400",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-blue-300",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-blue-600",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-4 font-semibold bg-purple-600",
                        },
                        [_vm._v("Genre")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-start-1 col-span-2 text-right tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("commons.fields.purchase_format_ratio")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-10 ml-3 grid grid-cols-3 text-center text-sm",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-5 font-semibold bg-red-400",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-5 font-semibold bg-yellow-500",
                        },
                        [_vm._v("Genre")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-1 px-2 py-1 mb-5 font-semibold bg-yellow-200",
                        },
                        [_vm._v("Genre")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-start-1 col-span-2 text-right tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("commons.fields.annual_purchase")) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-span-10 text-left ml-3" }, [
                    _vm._v("￥" + _vm._s(_vm._f("price")(_vm.annual_purchase))),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-start-1 col-span-2 text-right tracking-wide font-semibold",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("commons.fields.annual_purchase")) +
                          " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "col-span-10 text-left ml-3" }, [
                    _vm._v("￥" + _vm._s(_vm._f("price")(_vm.annual_purchase))),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("NoticeModal", {
        attrs: {
          modalName: "successNoticeModal",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
      _c(
        "modal",
        {
          attrs: {
            name: "tacModal",
            width: "80%",
            height: "80%",
            scrollable: false,
            adaptive: true,
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass:
                  "absolute close_btn h-5 w-5 top-2 right-2 sm:h-5 sm:w-5 sm:m-2 z-50",
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("tacModal")
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("FlShowTac", {
            attrs: {
              userType: "consumer",
              acknowledgeType: "button",
              uuid: _vm.consumerId,
            },
            on: {
              agree: function ($event) {
                return _vm.hideAndReload()
              },
            },
            model: {
              value: _vm.consumer.agree_to_terms,
              callback: function ($$v) {
                _vm.$set(_vm.consumer, "agree_to_terms", $$v)
              },
              expression: "consumer.agree_to_terms",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }